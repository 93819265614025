var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"items":_vm.notifications,"headers":_vm.headers,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.sushi_service.platform.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":item.sushi_service
          ? {
              name: 'Platform detail',
              params: { platformId: item.sushi_service.platform.id },
            }
          : {
              name: 'Usage data host detail',
              params: { dataHostId: item.data_host.id },
            }}},[_vm._v(_vm._s(item.sushi_service ? item.sushi_service.platform.name : item.data_host.name))])]}},{key:"item.type",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(`translation.notification.type.${item.type}`))+" ")]}},{key:"item.source",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(`translation.notification.source.${item.source}`))+" ")]}},{key:"item.start_date",fn:function({ item }){return [_c('IsoDate',{attrs:{"date":item.start_date}}),_vm._v(" – "),_c('IsoDate',{attrs:{"date":item.end_date}})]}},{key:"item.published_date",fn:function({ item }){return [_c('IsoDate',{attrs:{"date":item.published_date,"include-time":""}})]}},{key:"expanded-item",fn:function({ item, headers }){return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"color":"#f4f4f4"}},[(item.reports && item.reports.length)?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("label.notification.reports")))]),_vm._v(": "),_vm._l((item.reports),function(report){return _c('ReportTag',{key:report.report_id,attrs:{"report":report,"hide-mark":""}})})],2):_vm._e(),_vm._v(" "+_vm._s(item.message)+" ")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }