<template>
  <v-expansion-panel class="mt-6 ma-4" elevation="4" :key="counterVersion">
    <v-expansion-panel-header>
      <h3 class="text-h5">COUNTER {{ counterVersion }}</h3>
      <v-spacer></v-spacer>
      <div class="shrink">
        <AuditStatus
          v-if="audited && sushiService && sushiService.dataHost"
          :status="
            sushiService &&
            sushiService.dataHost &&
            sushiService.dataHost.audit_status
          "
        />
        <v-tooltip v-else-if="!audited" bottom max-width="600px">
          <template #activator="{ on }">
            <span v-on="on">
              <v-icon small color="error" class="mb-1"
                >mdi-close-circle-outline</v-icon
              >
              {{ $t("label.platform.notAudited") }}
            </span>
          </template>
          {{ $t("label.platform.notAuditedTT") }}
        </v-tooltip>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <v-row
        v-if="sushiService && sushiService.dataHost"
        class="justify-space-between mb-3"
      >
        <v-col cols="12" md="auto">
          <span class="font-weight-light mr-2">SUSHI data host:</span>
          <router-link
            :to="{
              name: 'Usage data host detail',
              params: { dataHostId: sushiService.dataHost.id },
            }"
            >{{ sushiService.dataHost.name }}</router-link
          >
        </v-col>
        <v-col v-if="audited" cols="12" md="auto">
          <div class="d-flex">
            <span class="font-weight-light mr-2"
              >{{ $t("label.info.lastAudit") }}:</span
            >
            <span class="ml-auto">
              <IsoDate :date="lastAuditDate" placeholder="-" />
            </span>
          </div>
          <div class="d-flex">
            <span class="font-weight-light mr-2"
              >{{ $t("label.info.upcomingAudit") }}:</span
            >
            <span class="ml-auto">
              <IsoDate :date="upcomingAuditDate" placeholder="-" />
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2 justify-space-between">
        <v-col cols="12" md="auto">
          <h4 class="text-h6">{{ $t("title.supportedReports") }}</h4>
          <table class="reports">
            <tr v-for="reportType in availableReports" :key="reportType.id">
              <th>{{ reportType.name }}</th>
              <td>
                <ReportTag
                  v-for="report in reportType.reports"
                  :report="report"
                  :key="report.report_id"
                  :active="reportTypeCodes.has(report.report_id)"
                  :hidden="
                    hideInactive && !reportTypeCodes.has(report.report_id)
                  "
                />
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" md="auto">
          <h4 class="text-h6 ml-4">{{ $t("title.contact") }}</h4>
          <ContactWidget
            :contact="sushiService.contact"
            :elevation="0"
            no-gutters
            v-if="sushiService && sushiService.contact"
          />
          <div v-else class="text--disabled ml-4">
            <v-icon color="grey lighten-1">mdi-close-circle-outline</v-icon>
            No information
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h4 class="text-h6 mt-4">{{ $t("title.sushiInfo") }}</h4>
          <SushiInfo v-if="sushiService" :sushi-service="sushiService" />

          <v-alert
            v-else
            type="warning"
            colored-border
            border="left"
            class="mt-8"
          >
            {{ $t("message.noSushiService") }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4 class="text-h6 mt-4">{{ $t("title.notifications") }}</h4>
          <NotificationList
            v-if="notifications.length"
            :notifications="notifications"
          />
          <p v-else class="text--secondary">
            {{ $t("message.noNotifications") }}
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4 class="text-h6 mt-4">{{ $t("title.migrationHistory") }}</h4>
          <MigrationList
            v-if="sushiService && sushiService.migrations.length"
            :migrations="sushiService.migrations"
          />
          <p v-else class="text--secondary">
            {{ $t("message.noMigrations") }}
          </p>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ReportTag from "./ReportTag";
import CounterAPI from "../lib/api";
import NotificationList from "./NotificationList";
import ContactWidget from "./ContactWidget";
import SushiInfo from "./SushiInfo";
import IsoDate from "./IsoDate";
import AuditStatus from "./AuditStatus";
import MigrationList from "./MigrationList.vue";

export default {
  name: "SushiServiceSection",

  components: {
    MigrationList,
    AuditStatus,
    IsoDate,
    SushiInfo,
    ContactWidget,
    NotificationList,
    ReportTag,
  },

  props: {
    counterVersion: { type: String, required: true },
    reports: { type: Array, default: () => [] },
    sushiService: { type: Object, required: false },
    availableReports: { type: Array, default: () => [] },
    audited: { type: Boolean, default: true },
  },

  data() {
    return {
      hideInactive: false,
      api: new CounterAPI(process.env.COUNTER_API_ROOT),
      notifications: [],
    };
  },

  computed: {
    reportTypeCodes() {
      return new Set([...this.reports.map((report) => report.report_id)]);
    },
    upcomingAuditDate() {
      return this.sushiService?.upcomingAudit?.start_date;
    },
    lastAuditDate() {
      return this.sushiService?.lastAudit?.finish_date;
    },
  },

  methods: {
    async getNotifications() {
      this.notifications = await this.api.getSushiServiceNotifications(
        this.sushiService
      );
      this.notifications.forEach(
        (notification) =>
          (notification.createdDate = notification.created.substr(0, 10))
      );
    },
  },

  mounted() {
    this.getNotifications();
  },
};
</script>

<style scoped lang="scss">
table.reports {
  th {
    text-align: right;
    font-weight: 300;
    padding-right: 1rem;
  }
}
</style>
