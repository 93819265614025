<template>
  <table class="overview" v-if="sushiService">
    <tr v-if="sushiService.url">
      <th>{{ $t("label.counter.sushiUrl") }}</th>
      <td></td>
      <td class="text--secondary text-caption">
        {{ sushiService.url }}
        <v-btn text icon @click="copyToClipboard(sushiService.url)">
          <v-icon color="primary">mdi-content-copy</v-icon>
        </v-btn>
      </td>
    </tr>

    <tr v-if="sushiService.customer_id_info">
      <th>{{ $t("label.counter.customerIdInfo") }}</th>
      <td></td>
      <td class="text--secondary text-caption">
        {{ sushiService.customer_id_info }}
      </td>
    </tr>

    <tr v-for="(field, index) in infoFields" :key="index">
      <th>{{ $t(field.label) }}</th>
      <td>
        <v-icon
          >{{
            `mdi-checkbox-${
              sushiService[field.checkboxField] ? "marked" : "blank"
            }-outline`
          }}
        </v-icon>
        <span class="sr-only">{{
          sushiService[field.checkboxField]
            ? $t("aria.checked")
            : $t("aria.unchecked")
        }}</span>
      </td>
      <td class="text--secondary text-caption">
        {{ sushiService[field.noteField] }}
      </td>
    </tr>

    <v-snackbar v-model="snackbar" :color="snackbarColor">
      <v-icon v-if="snackbarColor === 'success'" class="mr-2"
        >mdi-check-circle-outline</v-icon
      >
      {{ snackbarText }}
    </v-snackbar>
  </table>
</template>
<script>
import clipboard from "../mixins/clipboard";

export default {
  name: "SushiInfo",
  mixins: [clipboard],
  props: {
    sushiService: {},
  },

  data() {
    return {
      infoFieldsRaw: [
        {
          base: "requestor_id",
        },
        {
          base: "api_key",
        },
        {
          checkboxField: "credentials_auto_expire",
        },
        {
          base: "platform_attr",
          noteField: "platform_specific_info",
        },
        {
          checkboxField: "ip_address_authorization",
        },
        {
          checkboxField: "request_volume_limits_applied",
          noteField: "request_volume_limits_info",
        },
        {
          checkboxField: "customizations_in_place",
          noteField: "customizations_info",
        },
      ],
    };
  },

  computed: {
    infoFields() {
      return this.infoFieldsRaw.map((rec) => {
        if (!rec.checkboxField) rec.checkboxField = rec.base + "_required";
        if (!rec.noteField)
          rec.noteField = rec.base
            ? rec.base + "_info"
            : rec.checkboxField + "_info";
        if (!rec.label)
          rec.label = `label.counter.${rec.checkboxField.replace(
            /([-_]\w)/g,
            (g) => g[1].toUpperCase()
          )}`; // convert snake_case to camelCase
        return rec;
      });
    },
  },
};
</script>
<style scoped lang="scss">
table.overview {
  width: auto;
  border-collapse: collapse;

  th {
    text-align: left;
    padding: 0.25rem 1rem 0.25rem 0.75rem;
    color: #555555;
    font-weight: 600;
    font-size: (14rem/16);
    letter-spacing: -0.2px;
    width: 25%;
  }

  td {
    padding: 0.25rem 1rem 0.25rem 0.25rem;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
</style>
