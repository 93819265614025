export default {
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
    };
  },

  methods: {
    async copyToClipboard(text) {
      if (!navigator.clipboard) {
        this.showSnackbar(this.$t("message.textNotCopiedToClipboard"), "error");
      }
      try {
        await navigator.clipboard.writeText(text);
        this.showSnackbar(this.$t("message.textCopiedToClipboard"));
      } catch (error) {
        this.showSnackbar(this.$t("message.textNotCopiedToClipboard"), "error");
      }
    },

    showSnackbar(text, color = "success") {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
};
