<template>
  <div class="my-0 my-md-12">
    <PlatformDetail :platform-id="platformId" />
  </div>
</template>

<script>
import PlatformDetail from "../components/PlatformDetail";
export default {
  name: "PlatformDetailPage",

  components: { PlatformDetail },

  props: {
    platformId: { required: true, type: String },
  },
};
</script>

<style scoped></style>
