<template>
  <v-data-table
    :items="notifications"
    :headers="headers"
    :expanded.sync="expanded"
    show-expand
  >
    <template #item.sushi_service.platform.name="{ item }">
      <router-link
        :to="
          item.sushi_service
            ? {
                name: 'Platform detail',
                params: { platformId: item.sushi_service.platform.id },
              }
            : {
                name: 'Usage data host detail',
                params: { dataHostId: item.data_host.id },
              }
        "
        >{{
          item.sushi_service
            ? item.sushi_service.platform.name
            : item.data_host.name
        }}</router-link
      >
    </template>
    <template #item.type="{ item }">
      {{ $t(`translation.notification.type.${item.type}`) }}
    </template>
    <template #item.source="{ item }">
      {{ $t(`translation.notification.source.${item.source}`) }}
    </template>
    <template #item.start_date="{ item }">
      <IsoDate :date="item.start_date" /> &ndash;
      <IsoDate :date="item.end_date" />
    </template>
    <template #item.published_date="{ item }">
      <IsoDate :date="item.published_date" include-time />
    </template>

    <template #expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-0">
        <v-sheet class="pa-4" color="#f4f4f4">
          <p v-if="item.reports && item.reports.length">
            <strong>{{ $t("label.notification.reports") }}</strong
            >:
            <ReportTag
              v-for="report in item.reports"
              :report="report"
              :key="report.report_id"
              hide-mark
            />
          </p>
          {{ item.message }}
        </v-sheet>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import ReportTag from "./ReportTag";
import IsoDate from "./IsoDate";
export default {
  name: "NotificationList",
  components: { IsoDate, ReportTag },
  props: {
    notifications: { type: Array, required: true },
    showPlatform: { type: Boolean, default: false },
    showRelease: { type: Boolean, default: false },
  },

  data() {
    return {
      expanded: [],
    };
  },

  computed: {
    headers() {
      let out = [
        { text: this.$t("label.date.published"), value: "published_date" },
        { text: this.$t("label.notification.type"), value: "type" },
        { text: this.$t("label.notification.source"), value: "source" },
        { text: this.$t("label.notification.dateRange"), value: "start_date" },
        { text: this.$t("label.notification.subject"), value: "subject" },
      ];
      if (this.showPlatform) {
        out.splice(1, 0, {
          text: this.$t("label.notification.platformOrDataHost"),
          value: "sushi_service.platform.name",
        });
      }
      if (this.showRelease) {
        out.splice(2, 0, {
          text: this.$t("label.notification.release"),
          value: "sushi_service.counter_release",
        });
      }
      return out;
    },
  },
};
</script>

<style scoped></style>
