<template>
  <v-data-table :items="migrations" item-key="pk" :headers="headers">
    <template #item.migration_date="{ item }">
      {{ item.migration_date ? dateToMonth(item.migration_date) : "-" }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "MigrationList",

  props: {
    migrations: { type: Array, required: true },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("label.migration.migrationMonth"),
          value: "migration_date",
        },
        {
          text: this.$t("label.migration.dataHost"),
          value: "data_host_original_name",
        },
      ];
    },
  },

  methods: {
    dateToMonth(str) {
      return str.slice(0, 7);
    },
  },
};
</script>

<style scoped></style>
